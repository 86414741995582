// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-featured-projects-eras-js": () => import("./../../../src/pages/featured-projects/eras.js" /* webpackChunkName: "component---src-pages-featured-projects-eras-js" */),
  "component---src-pages-featured-projects-everyday-icing-js": () => import("./../../../src/pages/featured-projects/everyday-icing.js" /* webpackChunkName: "component---src-pages-featured-projects-everyday-icing-js" */),
  "component---src-pages-featured-projects-js": () => import("./../../../src/pages/featured-projects.js" /* webpackChunkName: "component---src-pages-featured-projects-js" */),
  "component---src-pages-featured-projects-workplace-one-js": () => import("./../../../src/pages/featured-projects/workplace-one.js" /* webpackChunkName: "component---src-pages-featured-projects-workplace-one-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lets-talk-js": () => import("./../../../src/pages/lets-talk.js" /* webpackChunkName: "component---src-pages-lets-talk-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

